// login 是引用axios基于request的无状态请求，获取token
import axios from "axios";
import { env } from "@/env";
export function logoutAPI() {
  const instance = axios.create({
    // laravel 测试地址APIj接口测试
    // baseURL: "http://laravel-study:8686/api/",
    baseURL: env.BaseUrl,
    timeout: 5000,
    headers: {
      Accept: "application/json",
    },
  });

  // 响应拦截器
  instance.interceptors.response.use(
    function (response) {
      return Promise.resolve(response.data);
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  return instance({
    url: "/logout",
    method: "post",
  });
}
