// login 不使用此API
import axios from "axios";
import router from "../router/index.js";
import { env } from "@/env.js";
export function request(config) {
  const instance = axios.create({
    baseURL: env.BaseUrl,
    timeout: 5000,
    headers: {
      Accept: "application/json",
    },
  });

  // 请求拦截
  instance.interceptors.request.use(function (config) {
    // Sanctum 请求头
    const token = window.sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      router.replace({
        path: "/login",
      });
    }
    return config;
  }),
    function (error) {
      return Promise.reject(error);
    };

  // 响应拦截器
  instance.interceptors.response.use(
    function (response) {
      if (response.status >= 200 && response.status <= 230) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(response.data);
      }
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // 返回实例对象
  return instance(config);
}
