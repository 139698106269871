<!--LogoIn page  -->
<template>
  <div>
    <div class="container">
      <div class="login-box">
        <div class="logo">
          <h3>V-Maintenance</h3>
        </div>
        <h1>登录</h1>
        <h3 v-show="!inputUserIdShow">{{ userName }} 欢迎您！</h3>
        <el-Form :model="loginInfo" :rules="rules" ref="loginInfo">
          <ElFormItem prop="userId"
            ><el-input
              v-show="inputUserIdShow"
              class="userid-input"
              v-model="loginInfo.userId"
              placeholder="请输入工号"
            ></el-input
          ></ElFormItem>
          <ElFormItem prop="password"
            ><el-input
              placeholder="请输入密码"
              v-model="loginInfo.password"
              v-on:keyup.enter.native="submit"
              show-password
              class="password-input"
            ></el-input
          ></ElFormItem>
          <a v-show="!inputUserIdShow" href="#/login" @click="changLogIn"
            >更换登录账户</a
          >
          <el-form-item>
            <el-row>
              <el-col :span="6" :offset="18"
                ><el-button
                  type="primary"
                  class="login-btn"
                  @click="submit"
                  :disabled="!loginLoading"
                  ><div>
                    <i v-if="loginLoading">LogIn</i>
                    <i class="el-icon-loading" v-if="!loginLoading"></i>
                  </div> </el-button
              ></el-col>
            </el-row>
          </el-form-item>
        </el-Form>
      </div>
      <div class="icp">
        Copyright © 2022 - {{ ThisYear }} {{ Author }}***
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >蒙ICP备2023000206号-1</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { loginAPI } from "../network/login/login.js";
import dayjs from "dayjs";
export default {
  name: "LogIn",
  components: {},
  data() {
    return {
      loginInfo: {
        userId: "",
        userName: "",
        password: "",
      },
      // 登陆信息记录
      inputUserIdShow: false,
      // 初始密码状态
      passwordInit: false,
      loginLoading: true,
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        userId: [
          {
            min: 6,
            max: 6,
            required: true,
            message: "请输入6位工号",
            trigger: "blur",
          },
        ],
      },
      Author: "AustinAn",
    };
  },
  mounted() {
    // 初始化登录框
    this.initLogIn();
  },
  computed: {
    ThisYear() {
      return dayjs().year();
    },
    userName() {
      return localStorage.getItem("userName");
    },
  },
  methods: {
    initLogIn() {
      // 验证页面刷新
      // if (localStorage.getItem("userName") == this.$store.state.user.userName) {
      //   this.$router.replace({ path: this.$route.query.redirect });
      // }
      if (localStorage.getItem("userName") && localStorage.getItem("userId")) {
        this.loginInfo.userId = localStorage.getItem("userId");
      } else {
        this.inputUserIdShow = true;
      }
    },
    changLogIn() {
      localStorage.removeItem("userName");
      localStorage.removeItem("userId");
      localStorage.removeItem("email");
      this.loginInfo.userId = "";
      this.inputUserIdShow = true;
    },
    submit() {
      this.loginLoading = false;
      // 判断密码状态
      this.$refs.loginInfo.validate((valid) => {
        if (valid) {
          loginAPI(this.loginInfo.userId, this.loginInfo.password)
            .then((res) => {
              // 输出登陆信息
              // console.log(res);
              // 抽取角色清单
              let rolesTemp = [];
              res.roles.forEach((element) => {
                rolesTemp.push(element.role);
              });
              let roleList = rolesTemp.join(",");
              // 判断密码状态
              if (this.loginInfo.password == "123456") {
                // password初始化状态记录
                window.sessionStorage.setItem("psStatus", 1);
              }
              // 留存登陆信息
              window.localStorage.setItem("userId", res.user.jobid);
              window.localStorage.setItem("userName", res.user.name);
              window.sessionStorage.setItem("roles", roleList);
              window.sessionStorage.setItem("token", res.token);
              this.$router.replace({
                path: "/home",
              });
              this.loginLoading = true;
            })
            .catch((err) => {
              console.log(err);
              this.$message({
                message: err.response.data.message,
                type: "error",
              });
              this.loginLoading = true;
            });
        } else {
          console.log("error");
        }
      });
      // 返回请求路由
    },
  },
};
</script>
<style lang="less" scoped>
a {
  text-decoration: none;
  color: #222;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
div {
  box-sizing: border-box;
}
.icon {
  width: 32px;
  height: 32px;
}
.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.login-box {
  min-width: 440px;
  max-width: 440px;
  padding: 0 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}
.logo img {
  height: 48px;
  margin-left: 30px;
  margin-top: 20px;
}
.login-box h1 {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #303133;
}
.login-box h3 {
  margin: 30px 20px;
  font-weight: 1000;
  color: #606266;
}
.login-box a {
  font-size: 14px;
  display: block;
}
.userid-input /deep/.el-input__inner {
  border: 0;
  border-bottom: 1px solid #222;
  border-radius: 0;
}
.password-input /deep/ .el-input__inner {
  border: 0;
  border-bottom: 1px solid #222;
  border-radius: 0;
}
.login-btn {
  margin: 20px;
  border-radius: 0;
}
.icp {
  position: absolute;
  bottom: 0px;
  padding: 5px 0;
  width: 100%;
  text-align: center;
  background-color: #dadada;
}
.login-btn {
  div {
    width: 40px;
  }
}
</style>
