<!-- ProfileBanner -->
<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-popover
          placement="bottom"
          ref="avaterOver"
          trigger="hover"
          content="test"
          width="160"
        >
          <div class="pop-menu">
            <div>
              {{ userName }}
            </div>
            <el-button type="text" @click="handleChangePassword"
              ><i class="el-icon-setting"></i>修改密码</el-button
            >
          </div></el-popover
        >
        <div class="avater">
          <el-avatar v-popover:avaterOver> {{ firstName }}</el-avatar>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="avater">
          <el-tooltip content="退出登录" effect="dark" placement="bottom" class="item">
          <el-button
            icon="el-icon-switch-button"
            circle
            @click="logout"
          ></el-button>
        </el-tooltip>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { logoutAPI } from "../network/logout/logout";
export default {
  name: "ProfileBanner",
  data() {
    return {};
  },
  computed: {
    firstName() {
      return [...window.localStorage.getItem("userName")][0];
    },
    userName() {
      return window.localStorage.getItem("userName");
    }
  },
  mounted() {
    // 绑定退出事件
    this.$bus.$on("logout", () => {
      this.logout();
    });
  },
  beforeDestroy() {
    this.$bus.$off("logout");
  },
  methods: {
    logout() {
      logoutAPI()
        .then((res) => {
          if (res.message == "Logged out") {
            this.handleLogout();
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.handleLogout();

          } else {
            this.$message({
              message: "服务器未知错误，请联系管理员",
              type: "warning",
            });
            this.$router.replace({
              path: "/login",
            });
          }
        });
    },
    handleLogout() {
      window.sessionStorage.clear();
       this.$message({
        message: "系统退出",
        type: "success",
        duration: 1000,
      });
      this.$router.replace({
        path: "/login",
      });
    },
    handleChangePassword(){
      this.$store.commit('HomePage/switchVisible')
    }
  },
};
</script>
<style scoped>
.avater {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-icon-setting {
  margin: 0 10px;
}
.pop-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.item{
  margin: 4px;
}
</style>
