<!-- 全局页面 -->
<template>
  <div class="home">
    <el-container>
      <el-header>
        <el-row :gutter="10">
          <el-col :span="20"><nav-banner></nav-banner></el-col>
          <el-col :span="4"><profile-banner></profile-banner></el-col>
        </el-row>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
    <!-- 密码初始化 -->
    <change-ps></change-ps>
  </div>
</template>

<script>
import ChangePs from "@/components/ChangePs.vue";
import NavBanner from "@/components/NavBanner.vue";
import ProfileBanner from "@/components/ProfileBanner.vue";

export default {
  name: "HomeView",
  components: {
    NavBanner,
    ProfileBanner,
    ChangePs,
  },
  data() {
    return {
      timer: "",
    };
  },
  created() {
    // 全局计时函数，登陆30分钟后，清除登陆状态
    this.timer = setTimeout(this.clearToken, 1000 * 60 * 60);
  },
  mounted() {},
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    clearToken() {
      window.sessionStorage.clear();
    },
  },
};
</script>
