import Vue from "vue";
// 引入elementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import App from "./App.vue";
// vue-router
import router from "./router";
// vuex
import store from "./store";

// 引入lodash
import _ from "lodash";
// 导入lodash
Vue.prototype._ = _;

Vue.config.productionTip = false;

Vue.use(ElementUI);

// 全局组件注册
// import scroll from "./utils/BScroll/scroll.vue";
// Vue.component("scroll", scroll);

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    // 安装全局事件总线
    Vue.prototype.$bus = this;
  },
}).$mount("#app");
