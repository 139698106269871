import Vue from "vue";
import Vuex from "vuex";

// 模块引入
import { HomePage } from "./modules/HomePage";
import { AuthCate } from "./modules/AuthCate";
import { QuickHandle } from "./modules/QuickHandle";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    HomePage,
    AuthCate,
    QuickHandle,
  },
});
