const HomePage = {
    namespaced: true,
    state:{
        passwordDialogVisible:false
    },
    mutations:{
        switchVisible(state){
            state.passwordDialogVisible=!state.passwordDialogVisible
        },
        recoverVisibleStatus(state){
            state.passwordDialogVisible=false;
        }
    }
}
export {HomePage};