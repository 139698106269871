<!-- Change password -->
<template>
  <div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      width="600px"
      @close="handleClose"
    >
      <el-form :model="password" :rules="rules">
        <el-form-item label="新密码" label-width="80px" prop="NewPassword">
          <el-input
            v-model="password.NewPassword"
            placeholder="输入新密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          label-width="80px"
          prop="ConfirmPassword"
        >
          <el-input
            v-model="password.ConfirmPassword"
            placeholder="确认密码"
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitPassword">确 定</el-button>
        <el-button @click="dialogFormVisible = false" v-if="!isInitPassword"
          >取 消</el-button
        >
        <el-button @click="handleClose" v-if="isInitPassword"
          >注销登陆</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/network/request";
export default {
  name: "ChangePs",
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.password.NewPassword) {
        console.log(rule);
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      password: {
        NewPassword: "",
        ConfirmPassword: "",
      },
      rules: {
        NewPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 11,
            message: "长度在 6 到 11 个字符",
            trigger: "blur",
          },
        ],
        ConfirmPassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    dialogFormVisible: {
      get() {
        return this.$store.state.HomePage.passwordDialogVisible;
      },
      set() {
        this.$store.commit("HomePage/switchVisible");
      },
    },
    // 密码初始化状态
    isInitPassword() {
      if (window.sessionStorage.getItem("psStatus") == 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    // 开发时关闭
    console.log("密码初始化");
    this._initPs();
  },
  destroyed() {
    this.recoverDialogStatus();
  },
  methods: {
    _initPs() {
      if (this.isInitPassword) {
        this.$store.commit("HomePage/switchVisible");
      }
    },
    handleClose() {
      if (this.isInitPassword) {
        this.$bus.$emit("logout");
      } else {
        console.log("closed");
        // this.$store.commit('HomePage/switchVisible')
      }
    },
    submitPassword() {
      request({
        url: "/change-password",
        method: "post",
        data: {
          password: this.password.NewPassword,
          password_confirmation: this.password.ConfirmPassword,
        },
      }).then(
        (res) => {
          // 修改密码状态
          window.sessionStorage.removeItem("psStatus");
          this.$message({
            message: res,
            type: "warning",
          });
          this.$bus.$emit("logout");
        },
        (err) => {
          this.$message({
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 结束时确保状态是false
    recoverDialogStatus() {
      this.$store.commit("HomePage/recoverVisibleStatus");
    },
  },
};
</script>
<style scoped></style>
