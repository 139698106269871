// 人员授权模块状态
const AuthCate = {
  namespaced: true,
  state: {
    addDialogVisible: false,
    authDetailVisible: false,
    authItemEditVisible: false,
    addAuthRecordVisible: false,
  },
  mutations: {
    switchdialogVisible(state) {
      state.addDialogVisible = !state.addDialogVisible;
    },
    switchDetailVisible(state) {
      state.authDetailVisible = !state.authDetailVisible;
    },
    switchEditVisible(state) {
      state.authItemEditVisible = !state.authItemEditVisible;
    },
    switchAuthRecordVisible(state) {
      state.addAuthRecordVisible = !state.addAuthRecordVisible;
    },
  },
};

export { AuthCate };
