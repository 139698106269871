<template>
  <div class="about">
    <el-row class="title">
      <el-col :span="20" :offset="2"> <h2>版本更新信息</h2></el-col>
    </el-row>
    <el-row>
      <el-col :span="20" :offset="2">
        <el-table :data="versionData" v-loading="dataLoading">
          <el-table-column
            label="版本号"
            prop="version"
            width="180"
          ></el-table-column>
          <el-table-column
            label="版本说明"
            prop="description"
          ></el-table-column>
          <el-table-column
            label="发布日期"
            prop="release_at"
            width="180"
          ></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { request } from "@/network/request";
export default {
  data() {
    return {
      versionData: [],
      dataLoading: true,
    };
  },
  mounted() {
    this.getVersionData();
  },
  methods: {
    getVersionData() {
      request({
        url: "/system-info",
        method: "get",
      })
        .then((res) => {
          this.versionData = res;
          this.dataLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.title {
  text-align: center;
}
</style>
