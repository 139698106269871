<!-- NavBanner -->
<template>
  <div>
    <el-menu
      mode="horizontal"
      :default-active="activePath"
      @select="handleSelect"
    >
      <el-menu-item
        v-for="item in menuList"
        :key="item.path"
        :index="item.path"
        >{{ item.title }}</el-menu-item
      >
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "NavBanner",
  data() {
    return {
      menuList: [
        {
          path: "/home/main",
          title: "首页",
        },
        {
          path: "/home/profile",
          title: "账号",
        },
        {
          path: "/home/about",
          title: "关于",
        },
        {
          path: "/home/work",
          title: "工作台",
        },
      ],
    };
  },
  computed: {
    activePath() {
      return this.$route.matched[1].path;
    },
  },
  mounted() {
    // 查看路由状态
    // console.log(this.$route);
  },
  methods: {
    handleSelect(index) {
      this.$router.replace(
        {
          path: index,
        },
        () => {},
        () => {}
      );
    },
  },
};
</script>
<style scoped></style>
