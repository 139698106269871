// 故障快速处理
const QuickHandle = {
  namespaced: true,
  state: {
    NewItemDialogVisible: false,
    DetailDialogVisible: false,
    EditDialogVisible: false,
  },
  mutations: {
    switchNewItemDialogVisible(state) {
      state.NewItemDialogVisible = !state.NewItemDialogVisible;
    },
    switchDetailDialogVisible(state) {
      state.DetailDialogVisible = !state.DetailDialogVisible;
    },
    switchEditDialogVisible(state) {
      state.EditDialogVisible = !state.EditDialogVisible;
    },
  },
};
export { QuickHandle };
