<!-- HomePage -->
<template>
  <div>
    <utilization-chart></utilization-chart>
  </div>
</template>

<script>
import UtilizationChart from "@/components/charts/utilizationChart.vue";
export default {
  components: { UtilizationChart },
  name: "HomePage",
  comments: {
    UtilizationChart,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped></style>
