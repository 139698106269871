import Vue from "vue";
import VueRouter from "vue-router";
// 直接引入home
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import HomePage from "../views/HomePage/HomePage.vue";
import LoginView from "../views/LoginView.vue";

// 懒加载引入profile页面

const ProfileView = () => import("../views/ProfileView.vue");
const WorkView = () => import("../views/WorkView.vue");
const WorkTable = () => import("../views/WorkPage/WorkTable.vue");
const AccountPage = () => import("../views/WorkPage/system/AccountPage.vue");
const RolesPage = () => import("../views/WorkPage/system/RolesPage.vue");
const quickHandlepage = () => import("../views/WorkPage/QuickHandlePage.vue");
const SystemInfopage = () =>
  import("../views/WorkPage/system/SystemInfoPage.vue");
const AuthView = () => import("../views/WorkPage/author/getAuthDetail.vue");
const AuthCateManager = () =>
  import("../views/WorkPage/author/AuthCateManager.vue");
const QuickQueryPage = () => import("../views/WorkPage/QuickQueryPage.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    redirect: "/login",
  },
  {
    path: "/home",
    name: "home",
    redirect: "/home/main",
    component: HomeView,
    children: [
      {
        path: "main",
        name: "main",
        component: HomePage,
      },
      {
        path: "about",
        name: "about",
        component: AboutView,
      },
      {
        path: "profile",
        name: "profile",
        component: ProfileView,
      },
      {
        path: "work",
        name: "work",
        component: WorkView,
        children: [
          {
            path: "systemConfig",
            name: "systemConfig",
            component: WorkTable,
            meta: {
              title: "系统设置",
              author: ["admin"],
            },
            children: [
              {
                path: "account",
                name: "account",
                component: AccountPage,
                meta: {
                  title: "账户管理",
                  author: ["admin"],
                },
              },
              {
                path: "roles",
                name: "roles",
                component: RolesPage,
                meta: {
                  title: "角色管理",
                  author: ["admin"],
                },
              },
              {
                path: "system-info",
                name: "system-info",
                component: SystemInfopage,
                meta: {
                  title: "版本管理",
                  author: ["admin"],
                },
              },
            ],
          },
          {
            path: "techo",
            name: "techo",
            component: WorkTable,
            meta: {
              title: "技术支援",
              author: ["admin", "techo", "maint"],
            },
            children: [
              {
                path: "quickHandle",
                name: "quickHandle",
                component: quickHandlepage,
                meta: {
                  title: "快速处理维护",
                  author: ["admin", "techo"],
                },
              },
              {
                path: "quickQuery",
                name: "quickQuery",
                component: QuickQueryPage,
                meta: {
                  title: "故障快速处理",
                  author: ["admin", "techo", "maint"],
                },
              },
            ],
          },
          {
            path: "auth",
            name: "auth",
            component: WorkTable,
            meta: {
              title: "人员管理",
              author: ["admin", "techo", "maint", "general"],
            },
            children: [
              {
                path: "getAuthDetail",
                name: "getAuthDetail",
                component: AuthView,
                meta: {
                  title: "人员授权查询",
                  author: ["admin", "techo", "maint", "general"],
                },
              },
              {
                path: "auth-cate-manager",
                name: "auth-cate-manager",
                component: AuthCateManager,
                meta: {
                  title: "授权项目管理",
                  author: ["admin", "techo"],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
];

const router = new VueRouter({
  routes,
});

// 导航守卫
router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !window.sessionStorage.getItem("token")) {
    next({
      name: "login",
    });
  } else {
    next();
  }
});

export default router;
