<template>
  <div>
    <LineChartGenerator
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
    ></LineChartGenerator>
  </div>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "LineChart",
  components: { LineChartGenerator },
  data() {
    return {
      chartData: {
        labels: ["1月", "2月", "3月"],
        datasets: [
          { label: "Data One", backgroundColor: "#f87979", data: [40, 20, 12] },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
};
</script>
